export default {
  // ? ==========================
  // ? =====  Multilocation  ====
  // ? ==========================

  multiLocation: {},

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    '.container': {},
    '.containerScrolled': {
      backgroundColor: 'black'
    },

    '.smallNavMenu': {
      '.navItem': {
        a: {
          fontSize: '0.85rem',
          letterSpacing: '0px',
          color: 'white'
        }
      }
    },
    '.logoLocationContainer': {
      position: 'static',
      mr: 'auto',
      img: {
        filter: 'unset'
      }
    },
    '.hamburger': {
      borderColor: 'text',
      div: {
        backgroundColor: 'text'
      }
    },
    '.navMenuOpen': {
      color: 'text',
      '.socialContainer': {
        svg: {
          path: {
            fill: 'text'
          }
        }
      }
    },

    '.navItemDropdownMenu': {
      width: 'fit-content'
    }
  },

  footer: {
    borderTop: 'solid 2px',
    borderColor: 'text',
    '.image': {},
    '.multiButtonContainer': {
      a: {}
    },
    '.contactDetails-container': {},
    '.copyright': {},
    '.socialIconsContainer': {
      a: {}
    },
    '.gonationLogo': {},
    '.poweredByText': {
      justifyContent: 'flex-start'
    }
  },

  ctaWidget: {},

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    // variant: 'customVariants.gradientText',
    fontSize: ['1.4rem', '1.5rem', '1.7rem', '1.8rem', '2rem'],
    fontWeight: 'normal',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textAlign: 'left',
    marginBottom: '1rem',
    textTransform: 'uppercase',
    fontFamily: 'heading',
    fontWeight: 'bold'
  },
  subtitle: {
    width: 'fit-content',
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: ['2rem', '2.25rem', '2.5rem', '2.75rem', '3rem'],
    marginBottom: '1rem',
    border: 'none',
    fontFamily: 'subheading',
    fontWeight: '400',
    color: 'white',
    letterSpacing: '4px'
  },
  text: {
    lineHeight: '1.75',
    fontSize: '0.85rem',
    p: {
      lineHeight: '1.75',
      fontSize: 'inherit',
      textAlign: 'left'
    }
  },

  centerBlock: {
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      order: '5'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  sideBySide1: {
    padding: ['1rem', '', '2rem', '3rem'],
    '.lazyload-wrapper': {
      minHeight: ['', '', '50vh'],
      maxHeight: ['', '', '80vh'],
      justifyContent: 'flex-start'
    },

    '.content': {
      position: 'relative',
      alignItems: 'flex-start',
      margin: ['1rem 0rem', '', '0rem'],
      padding: ['2rem 1rem 2rem 3rem']
    },
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      order: '5'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  sideBySide2: {
    variant: 'customVariants.sideBySide1'
  },

  contactUsBar: {
    backgroundColor: '#ada89d78',
    '.title': {
      marginBottom: '0rem'
    }
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    // height: '75vh',
    // marginTop: ['74px', '84px', '100px'],
    // height: ['calc(100vh - 74px)', 'calc(100vh - 84px)', 'calc(100vh - 100px)'],
    // '.slick-initialized .slick-slide > div, .slick-slider, .slick-list, .slick-track, .slick-slide > div, .slick-slide img': {
    //   height: [
    //     'calc(100vh - 74px)',
    //     'calc(100vh - 84px)',
    //     'calc(100vh - 100px)',
    //   ],
    // },
    '.logoHero': {
      maxWidth: '700px',
      margin: '0rem auto 1.5rem'
    },
    '.hero_content_container': {
      // backgroundColor: '#ffffff85',
      margin: '0rem',
      maxWidth: '600px',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      color: 'white',
      textAlign: 'center',
      backgroundColor: '#000000c2',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      // border: 'solid 4px',
      // borderColor: 'text',
      width: '90%'
    },
    '.title': {
      variant: 'customVariants.title',
      border: 'none',
      margin: '0rem 0rem 1rem',
      padding: '0rem',
      order: '1',
      color: 'white',
      textAlign: 'center'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      textAlign: 'center',
      order: '2',
      color: 'text'
      // display: 'none',
    },
    '.text': {
      variant: 'customVariants.text',

      order: '3',

      p: {
        color: 'white'
      }
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.primary',
      backgroundColor: 'black'
    },
    '::after': {
      content: "''",
      position: 'absolute',
      top: '0px',
      left: '0px',
      right: '0px',
      bottom: '0px',
      background: 'linear-gradient(180deg,black,rgb(0 0 0 / 41%) 0%,rgb(0 0 0 / 0%) 50%,black 95%,black)'
    }
  },

  homepageHeroShout: {
    display: 'none'
  },

  homepageShout: {
    '.text': {
      variant: 'customVariants.text',
      background: 'unset'
    },
    '.title': {
      color: 'text'
    },
    '.containerPopUp': {
      backgroundColor: 'black',
      maxWidth: '600px',
      '.shoutContentContainerPopUp': {
        color: 'white'
      },
      '.image': {
        objectFit: 'contain',
        objectPosition: 'top'
      }
    }
  },

  homepageAbout: {
    background: 'none',
    padding: '5vh 1rem 0rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    'div.lazyload-wrapper ': {
      position: 'relative',
      '::before': {
        width: '100%',
        height: '100%',
        position: 'absolute',
        left: '0rem',
        top: '0rem',
        content: "''",
        background: 'radial-gradient(circle,rgb(0 0 0 / 0%) 0%,rgba(0,0,0,1) 70%,rgba(0,0,0,1) 100%)'
      }
    },
    '.title': {
      variant: 'customVariants.title',
      marginBottom: '1.5rem',

      order: '1'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      marginBottom: '1.5rem',

      order: '2'
    },

    '.text': {
      variant: 'customVariants.text',
      whiteSpace: 'pre-wrap',
      order: '3',

      p: {
        letterSpacing: '1px',
        lineHeight: '1.7'
      }
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  eventsSlider: {
    '.recurring-header': {
      fontSize: '0rem',
      '::after': {
        fontSize: '2rem',
        content: "'Events'"
      }
    }
  },

  homepageEvents: {},

  homepagePrivateEvents: {
    variant: 'customVariants.centerBlock',
    minHeight: '80vh',
    background:
      'radial-gradient(circle,rgb(68 57 128) 0%,rgb(19 59 128 / 53%) 20%,rgb(0 0 0 / 83%) 50%,rgb(32 19 19 / 0%) 80%,rgba(0,0,0,1) 100%)',
    backgroundAttachment: ['', '', 'unset'],

    '.text': {
      '*': {
        textAlign: ['', '', 'center']
      }
    }
  },

  homepageMobile: {
    backgroundSize: '80%',
    backgroundAttachment: ['', '', 'unset'],
    my: '5vh',
    padding: '0rem',
    minHeight: '80vh',
    backgroundPosition: 'left center',
    alignItems: 'center',
    position: 'relative',
    flexDirection: ['column', '', 'row'],
    '.content': {
      position: ['', '', 'absolute'],
      backgroundColor: '#1b1b1b',
      left: '0rem',
      top: '50%',
      transform: ['', '', 'translateY(-50%)'],
      width: ['100%', '', '45%', '35%'],
      padding: '3rem',
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'left',
      '.title': {
        variant: 'customVariants.title',
        mb: 2
      },
      '.subtitle': {
        variant: 'customVariants.subtitle',
        mb: 2
      },
      '.text': {
        variant: 'customVariants.text',
        mb: 2
      },
      a: {
        order: '5'
      },
      '.ctaButton': {
        variant: 'buttons.primary'
      }
    },
    '.lazyload-wrapper': {
      width: ['', '', '80%'],
      margin: ['', '', '0rem 0rem 0rem auto']
    }
  },
  homepageEmployment: {
    variant: 'customVariants.homepageMobile',
    backgroundSize: '80%',
    backgroundAttachment: ['', '', 'unset'],
    my: '5vh',
    padding: '0rem',
    minHeight: '80vh',
    backgroundPosition: 'left center',
    alignItems: 'center',
    position: 'relative',
    'div.content': {
      right: '0rem',
      left: 'unset'
    },
    'div.lazyload-wrapper': {
      margin: ['', '', '0rem auto 0rem 0rem']
    }
  },

  homepageSocial: {
    marginBottom: '10vh'
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    variant: 'customVariants.sideBySide1',
    // backgroundColor: 'black',
    'div.content': {
      padding: ['1rem', '2rem', '1rem 3rem 1rem 1rem', '1rem 4rem 1rem 1rem'],
      // width: ['100%', '', '70%', '70%'],
      // width: ['100%', '100%', '100%', '100%'],
      '.subtitle': {
        // color: 'white',
      },
      // color: 'white',
      '.text': {
        '> *': {
          // color: 'white',
        }
      }
    },
    'div.lazyload-wrapper': {
      // width: ['100%', '', '35%', '35%'],
      // display: 'none',
    }
  },

  // ? ========================
  // ? ====  Menu page  ====
  // ? ========================

  // menu: {},
  servicesPage: {
    '#sec-z-i2jbTkT0qwyszOhAySNg': {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      '> .menuContainer': {
        width: '100%'
      },
      '> .allInContainer': {
        width: ['100%', '', 'calc(50% - 2rem)'],
        margin: ['0rem', '', '1rem'],
        border: 'solid 1px lightgrey',

        flexGrow: '1',
        '.menuItemsContainer': {
          display: 'flex',
          flexDirection: 'column',
          width: '100%'
        },
        '.menuItemContainerImgActive': {
          width: '100%'
        },
        '.menuItemContentContainer': {},
        '.menuSectionTitle': {
          textAlign: 'left',
          padding: '1.5rem',
          borderBottom: '1px solid lightgrey'
        },
        '.menuItemName': {
          fontWeight: 'normal',
          fontSize: '1.25rem',
          margin: '0rem 0rem 0.5rem',
          '::before': {
            content: "'➤'",
            margin: '0.5rem'
          }
        },
        '.menuItemDescription': {
          opacity: '0.7'
        }
      }
    }
  },

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    marginTop: '170px',
    '.albumTitle': {
      padding: '0.5rem'
    },
    '.albumName': {
      display: 'none'
    }
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.events-container': {
      maxWidth: 'unset'
    },
    '.no-events-container': {
      padding: '10rem 1rem',
      img: {
        maxHeight: '70px'
        // filter: 'brightness(0) invert(1)',
      }
    },
    '.eventItemContainer': {
      padding: ['0.5rem', '1rem', '1.5rem', '2rem', '3rem'],
      flexDirection: ['column', 'column', 'row', 'row', 'row'],
      margin: '1.5rem 0rem 1.5rem',
      boxShadow: '2px 2px 10px lightgrey'
    },

    '.eventItemImage': {
      minHeight: 'unset',
      width: ['100%', '100%', '50%', '40%', '35%'],
      // height: '100%',
      maxHeight: 'unset',
      objectFit: 'contain',
      objectPosition: 'top',
      cursor: 'pointer',
      transition: 'all ease-in-out 0.8s',
      ':hover': {
        // opacity: '0.8',
        transform: 'scale(1.05)',
        filter: 'brightness(0.6)'
      }
      // border: 'solid 1px lightgrey',
    },
    '.eventItemContent': {
      width: ['100%', '100%', '50%', '60%', '65%'],
      padding: ['1rem', '1.5rem', '2rem', '3rem'],
      marginTop: ['1.5rem', '', '0rem'],
      paddingTop: ['1rem', '', '0rem'],
      borderTop: ['1px solid', '1px solid', 'none', ''],
      borderColor: ['secondary', 'secondary']
    },
    '.eventDays, .eventDate': {
      color: 'primary'
    },
    '.eventItemTitle': {
      lineHeight: ['1.5', '1.5', '1.5', '1.75', '1.75']
    },

    '.eventItemDescription': {
      maxHeight: 'unset',
      overflowY: 'scroll',
      fontSize: ['1rem', '', '', '1.1rem', '1.2rem'],
      lineHeight: ['1.5', '1.5', '1.5', '1.75', '1.75'],
      '::-webkit-scrollbar': {
        display: 'none'
      },
      '-ms-overflow-style': 'none' /* IE and Edge */,
      scrollbarWidth: 'none'
    },
    '.eventCTABtns': {
      marginTop: '1rem',
      a: {
        variant: 'buttons.primary',
        textDecoration: 'none'
      }
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    order: '4',
    h2: {
      variant: 'customVariants.title',
      order: 'unset',
      fontSize: ['1.75rem', '2rem', '2rem', '2.5rem']
    },
    '.text': {
      color: 'text'
    },
    backgroundPosition: 'center center',
    color: 'text',
    backgroundColor: 'secondary',
    padding: ['3rem 1rem', '', '6rem 1rem'],
    '.inputField, .textField ': {
      fontWeight: '300',
      borderColor: 'text',
      borderWidth: '2px',
      borderRadius: '0px',
      padding: '1rem 0.5rem',
      color: 'text',
      '::placeholder': {
        color: 'text'
      }
    },

    '.submitBtn': {
      variant: 'buttons.secondary',
      height: 'unset',
      width: 'auto'
    }
  },

  locationMap: {
    order: '3',
    '.content_container': {
      padding: ['0rem', '', '', '3rem']
    },
    'h3.title': {
      variant: 'customVariants.title',
      justifyContent: 'center',
      display: 'flex',
      order: 'unset',
      width: '100%',
      color: 'white',
      padding: '0.5rem 0.5rem 1rem',
      textAlign: 'center',
      fontSize: ['1.1rem', '1.2rem', '1.3rem', '1.5rem', '1.6rem'],
      textAlign: 'center',
      marginBottom: '0rem',
      alignItems: 'center',
      paddingTop: '2rem',
      borderTop: '1px solid lightgrey'
    },
    '.content_container': {
      padding: '1rem'
    },
    '.contactDetails-container': {
      padding: '0rem 1rem 2rem'
    },
    '.dayContainer': {
      mb: '0rem'
    },
    '.hours-section': {
      maxWidth: 'unset',
      padding: '0rem',
      '.hoursContainer': {
        padding: ['1rem 0rem', '1rem 2rem 0rem', '1rem 4rem 0rem', '2rem 5rem 0rem']
      }
    },
    '.dayofWeekText': {
      margin: '0 auto 1rem 0',
      borderBottom: '1px solid'
    },
    '.textContent': {
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      display: 'flex'
    },
    iframe: {
      filter: 'invert(0.85)'
    }
  },

  // ? ========================
  // ? ====  Press page  ====
  // ? ========================

  pressMenu: {
    padding: ['1rem', '1rem', '1rem', '1rem'],
    backgroundColor: 'background',
    '.textContent': {
      backgroundColor: 'black'
    },
    '.imageContainer': {
      backgroundColor: '#8a7d74',
      img: {
        objectFit: 'contain',
        padding: '1rem'
      }
    }
  },

  pressPage: {
    backgroundColor: 'background'
  },

  // ? ========================
  // ? =======  blog   ========
  // ? ========================
  blogMenu: {
    padding: ['1rem', '1rem', '1rem', '1rem'],
    '.box': {
      backgroundColor: 'lightgrey'
    }
  },

  blogPage: {},

  // ? ========================
  // ? =====  services   ======
  // ? ========================

  menu: {
    '.tabsRow': {
      mb: '2rem'
    },
    '#menuTab': {
      border: 'solid 3px',
      borderColor: 'text',
      fontSize: '22px'
    },
    '#menuTabActive': {
      border: 'solid 2px',
      borderColor: 'primary',
      fontSize: '22px'
    },
    '.menuSectionTitle': {
      backgroundColor: 'primary',
      padding: '1rem',
      margin: ['0rem 0rem 2rem', '', '0rem 1.5rem 1.5rem'],
      fontSize: ['1.5rem', '1.75rem', '2rem', '2rem', '2rem']
    },
    '.menuSectionDescription': {
      textAlign: 'center',
      padding: '1.5rem',
      display: 'flex',
      width: '100%',
      justifyContent: 'center'
    },
    '.menuItemContainer, .menuItemContainerImgActive': {},
    '.menuItemImageContainer': {},
    '.itemImageFill': {},
    '.menuItemContentContainer': {},
    '.menuItemName': {},

    '.menuItemDescription': {
      color: '#b5abab'
    }
  }
}
